import MainLayout from '@components/layout/MainLayout'
import { useSearch } from '@contexts/search'
import { ListingsResponse } from '@service/booking.types'
import type { NextPage } from 'next'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { defaultDelivery, disabledHomePage } from '@util/config'
import { CitySettingsResponse } from '@service/configuration.types'
import { GATrackingActions, GATrackingCategories, MarketingBannerType } from '@util/enums'
import { BookingActionKind, useGlobalState } from '@contexts/global'
import { device } from '@util/responsive'
import { useMediaQuery } from '@hooks/mediaQuery'
import { useDateCalculation } from '@hooks/useDateCalculation'
import { useScroll } from '@hooks/useScroll'
import useMountEffect from '@hooks/useMountEffect'
import { MobileBookingQuickFilter } from '@components/modules/Search/MobileBookingQuickFilter/MobileBookingQuickFilter'
import { useRouter } from 'next/router'
import { CarList } from '@components/modules/Cars/CarList'
import { DesktopProductSwitcher } from '@components/modules/Search/DesktopProductSwitcher'
import { useListingQuery } from '@components/modules/Car/useListingQuery'
import debounce from 'lodash/debounce'
import { useCommon } from '@contexts/common'
import dynamic from 'next/dynamic'
import { getISODate } from '@util/functions'
import { MetaTags } from '@components/Meta'
import { useCookie } from '@hooks/useCookie'
import { Filter } from '@components/modules/Car/Filter'
import { sendUserId, sendVisitorType, trackEvent } from '@util/ga'
import { useAuth } from '@contexts/auth'
import { CurrentBookingsWidget } from '@components/CurrentBookingsWidget'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { UserType } from '@components/modules/CarDetails/constants'

const MarketingBanner = dynamic(
    () => import('@components/global/MarketingBanner').then(({ MarketingBanner }) => MarketingBanner),
    { ssr: false },
)

export async function getServerSideProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
    }
}

const Cars: NextPage<{ cars: ListingsResponse; citySettings: CitySettingsResponse }> = () => {
    const { bookingDispatch, bookingState } = useGlobalState()
    useDateCalculation()
    const { changeProductTo } = useCommon()
    const canonical = window.location.href.split('?')[0]
    const { listingFilter: listingsParams } = useListingQuery()
    const { datePickerOverlay, setDatepickerOverlay, fromDate, toDate, fromPickerTime, toPickerTime } = useSearch()
    const { query, pathname, replace } = useRouter()
    const { user } = useAuth()
    const { isScrolled } = useScroll(200)
    const isDesktop = useMediaQuery(device.laptop)

    const debouncedCheckScroll = debounce(() => isScrolled, 10)

    // get value of ksa_user cookie
    const ksaUser = useCookie('ksa_user')

    const handleTrackingEvents = () => {
        const hasTrackedFirstOpen = sessionStorage.getItem('hasTrackedFirstOpen')

        if (!hasTrackedFirstOpen && (user?.userId || user?.careemId)) {
            sendUserId(user?.userId)
            // Ecommerce GTM Events for User Type
            if (user?.careemId) {
                sendVisitorType(UserType.CAREEM)
            } else {
                sendVisitorType(UserType.STANDALONE)
            }
            trackEvent({
                action: GATrackingActions.H_FIRST_OPEN,
                category: GATrackingCategories.HOMEPAGE,
                value: {
                    userId: user?.userId || '',
                    careemId: user?.careemId || '',
                    isDesktop: isDesktop,
                },
            })

            sessionStorage.setItem('hasTrackedFirstOpen', 'true')
        }

        trackEvent({
            action: GATrackingActions.H_OPEN,
            category: GATrackingCategories.HOMEPAGE,
            value: {
                userId: user?.userId || '',
                careemId: user?.careemId || '',
                isDesktop: isDesktop,
            },
        })
    }

    useEffect(() => {
        handleTrackingEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.userId, user?.careemId])

    useMountEffect(() => {
        bookingDispatch({
            type: BookingActionKind.CHANGE_DELIVERY_TYPE,
            value: defaultDelivery,
        })
        changeProductTo(bookingState.productType)
    })

    const myRef = useRef()

    useEffect(() => {
        // URL change for only product type switching
        if (fromDate && fromPickerTime) {
            const handoverDateTime = getISODate(fromDate, fromPickerTime?.startDate)

            if (toDate && toPickerTime) {
                const handbackDateTime = getISODate(toDate, toPickerTime.startDate)
                const {
                    maxDailyPrice,
                    minDailyPrice,
                    carType,
                    productType,
                    makeIds,
                    modelIds,
                    engineTypes,
                    gearboxTypes,
                    carFeatureIds,
                    ...routerQuery
                } = query

                const queryParams = {
                    ...routerQuery,
                    from: handoverDateTime,
                    to: handbackDateTime,
                    productType: bookingState.productType,
                }

                replace(
                    {
                        pathname,
                        query: queryParams,
                    },
                    undefined,
                    { shallow: true },
                )
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingState.productType])

    useEffect(() => {
        if (fromDate && fromPickerTime) {
            const handoverDateTime = getISODate(fromDate, fromPickerTime?.startDate)

            if (toDate && toPickerTime) {
                const handbackDateTime = getISODate(toDate, toPickerTime.startDate)

                const queryParams = {
                    ...query,
                    from: handoverDateTime,
                    to: handbackDateTime,
                    productType: bookingState.productType,
                }

                replace(
                    {
                        pathname,
                        query: queryParams,
                    },
                    undefined,
                    { shallow: true },
                )
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate, fromPickerTime, toPickerTime])

    return (
        <MainLayout showBackButton={!disabledHomePage} noWarning={!isDesktop}>
            <MetaTags pageType={'listings'} canonical={canonical} />
            <Wrapper
                marginTop={0}
                onClick={() => datePickerOverlay && setDatepickerOverlay(false)}
                scrolled={isScrolled}
            >
                <CurrentBookingsWidget />
                {ksaUser === 'true' && listingsParams.cityCode && (
                    <MarketingBanner type={MarketingBannerType.KSAVisitor} cityCode={listingsParams.cityCode} />
                )}

                {!isDesktop && listingsParams.cityCode && (
                    <MarketingBanner type={MarketingBannerType.Secondary} cityCode={listingsParams.cityCode} />
                )}

                {isDesktop && <DesktopProductSwitcher />}
                {!isDesktop && !debouncedCheckScroll() && <MobileBookingQuickFilter />}

                {isDesktop && listingsParams.cityCode && (
                    <MarketingBanner type={MarketingBannerType.Secondary} cityCode={listingsParams.cityCode} />
                )}

                <CarList listingsParams={listingsParams} ref={myRef} />

                <Filter ref={myRef} removeForMobile={true} />
            </Wrapper>
        </MainLayout>
    )
}

type WrapperType = {
    scrolled: boolean
    marginTop?: number
}

const Wrapper = styled.div<WrapperType>`
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    padding: 0px var(--padding);
    transition: 0.5s all;
    @media ${device.laptop} {
        background: none;
        padding: 0;
    }
`

export default Cars
