import { ProductType } from '@service/configuration.types'

export enum AdditionalFeatureType {
    BASIC_INSURANCE = 'BASIC_INSURANCE',
    KM_PER_DAY = 'KM_PER_DAY',
    ROADSIDE_ASSISTANCE = 'ROADSIDE_ASSISTANCE',
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
    FREE_CANCELLATION = 'FREE_CANCELLATION',
    NO_DEPOSIT = 'NO_DEPOSIT',
}

export const getAdditionalFeatureTypes = (productType: ProductType, isLuxuryCar: boolean): AdditionalFeatureType[] => {
    if (productType === ProductType.DAILY) {
        return [
            AdditionalFeatureType.BASIC_INSURANCE,
            AdditionalFeatureType.KM_PER_DAY,
            AdditionalFeatureType.ROADSIDE_ASSISTANCE,
            AdditionalFeatureType.CUSTOMER_SERVICE,
        ]
    }
    if (productType === ProductType.MONTHLY) {
        return [
            AdditionalFeatureType.FREE_CANCELLATION,
            ...(isLuxuryCar ? [] : [AdditionalFeatureType.NO_DEPOSIT]),
            AdditionalFeatureType.ROADSIDE_ASSISTANCE,
            AdditionalFeatureType.CUSTOMER_SERVICE,
        ]
    }
    return []
}

export enum BookingFeatureType {
    CAR_PREFERENCE = 'CAR_PREFERENCE',
    DOOR_TO_DOOR = 'DOOR_TO_DOOR',
    CANCELLATION = 'CANCELLATION',
    DISTANCE = 'DISTANCE',
    FULL_INSURANCE = 'FULL_INSURANCE',
}

export const ProductTypeToBookingFeatureTypes = {
    [ProductType.DAILY]: [
        BookingFeatureType.CAR_PREFERENCE,
        BookingFeatureType.DOOR_TO_DOOR,
        BookingFeatureType.CANCELLATION,
    ],
    [ProductType.MONTHLY]: [
        BookingFeatureType.DISTANCE,
        BookingFeatureType.CAR_PREFERENCE,
        BookingFeatureType.DOOR_TO_DOOR,
        BookingFeatureType.FULL_INSURANCE,
    ],
}

export const UserType = {
    CAREEM: 'Careem',
    STANDALONE: 'Standalone',
}
