import { useInfiniteQuery } from 'react-query'
import { DesktopCarCard } from '@components/global/DesktopCarCard'
import { BarSpinner } from '@components/global/icons/SpinnerBar'
import InfiniteScroll from 'react-infinite-scroll-component'
import { MobileCarCard } from '@components/global/MobileCarCard'
import { ListingFilter, ListingsResponse, ListingsResponseData } from '@service/booking.types'
import { bookingService } from '@service/booking'
import { forwardRef, useState } from 'react'
import { sendEcommerceEvent, trackEvent } from '@util/ga'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device, size } from '@util/responsive'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useAuth } from '@contexts/auth'
import { GATrackingActions, GATrackingCategories } from '@util/enums'
import { useCommon } from '@contexts/common'
import { useGlobalState } from '@contexts/global'
import { useRouterQuery } from '@hooks/useRouterQuery'

type CarListProps = {
    listingsParams: ListingFilter
}

const CarListComponent = ({ listingsParams }: CarListProps, ref) => {
    const { t } = useTranslation()
    const isDesktop = useMediaQuery(device.laptop)
    const { user } = useAuth()
    const [carData, setCarData] = useState<ListingsResponseData[] | []>([])
    const { handlerFullScreenLoaderState } = useCommon()
    const { bookingState } = useGlobalState()
    const query = useRouterQuery()
    const queriedCity = query?.city as string

    const { isFetchedAfterMount, fetchNextPage, hasNextPage } = useInfiniteQuery<ListingsResponse>(
        ['listings', listingsParams],
        ({ pageParam = listingsParams }) => bookingService.getListings(pageParam).then((res) => res.data),
        {
            retry: 3,
            initialData: { pages: [], pageParams: [listingsParams] },
            getNextPageParam: (lastPage) => {
                if (lastPage && lastPage?.paginationInfo?.currentPage < lastPage?.paginationInfo?.totalPages) {
                    return {
                        ...listingsParams,
                        page: lastPage.paginationInfo.currentPage + 1,
                    }
                }
                return undefined
            },
            onSuccess: (data) => {
                handlerFullScreenLoaderState(false)
                setCarData(data?.pages.flatMap((page) => page.data) ?? [])
            },
            onError: () => {
                handlerFullScreenLoaderState(false)
                setCarData([])
            },
        },
    )

    const openFilter = () => {
        const currentRef: any = ref?.current
        currentRef?.openFromParent()
    }

    if (!carData) return null

    if (!isFetchedAfterMount) {
        if (!isDesktop) handlerFullScreenLoaderState(true)
        return (
            <SpinnerWrapper marginTopBottom={50} direction="column" align="center">
                <p>{t('carListingLoadingHeader', 'Please wait')}</p>
                <span>{t('carListingLoadingSubText', 'we are loading the cars')}</span>
            </SpinnerWrapper>
        )
    }

    const onClickHandler = (car, index) => {
        const returnProductTypeID = bookingState.productType === 'DAILY' ? 2 : 1
        trackEvent({
            action: GATrackingActions.H_CARD_CLICK,
            category: GATrackingCategories.CARD,
            value: {
                userId: user?.userId || '',
                careemId: user?.careemId || '',
                isDesktop: isDesktop,
            },
        })

        // Ecommerce GTM Events for Item Selection
        sendEcommerceEvent('select_item', {
            item_list_id: returnProductTypeID,
            item_list_name: bookingState.productType,
            items: [
                {
                    item_id: car.id,
                    item_name: `${car.make} ${car.model}`,
                    affiliation: user?.careemId ? 'Careem' : 'Standalone',
                    coupon: '',
                    discount: 0,
                    index: index,
                    item_brand: car.make,
                    item_category: bookingState.productType,
                    item_category2: '',
                    item_category3: '',
                    item_category4: '',
                    item_category5: '',
                    item_list_id: returnProductTypeID,
                    item_list_name: bookingState.productType,
                    item_variant: car.model,
                    location_id: queriedCity,
                    price: car?.pricingDetails?.periodPrice,
                    quantity: 1,
                },
            ],
        })
    }

    return (
        <InfiniteScroll
            dataLength={carData.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
                <SpinnerWrapper>
                    <BarSpinner />
                </SpinnerWrapper>
            }
        >
            <CarWrapper>
                {carData.length ? (
                    carData.map((car, index) => (
                        <CarCardWrapper key={car.id} data-tesdtid={`rental_listing_item_${car.id}`}>
                            <div onClick={() => onClickHandler(car, index)}>
                                {isDesktop ? (
                                    <DesktopCarCard key={car.id} car={car} preload />
                                ) : (
                                    <MobileCarCard key={car.id} car={car} preload={index < 3} />
                                )}
                            </div>
                        </CarCardWrapper>
                    ))
                ) : (
                    <NoResults>
                        <NoResultsImage>
                            <img src="/rental/svg/no-cars-found.svg" alt="" />
                        </NoResultsImage>
                        <NoResultsTitle>{t('carFiltersNotFoundTitle', 'No cars found')}</NoResultsTitle>
                        <NoResultsText>
                            {t('carFiltersNotFoundText', 'Try changing your filters or adjusting your dates')}
                        </NoResultsText>
                        <RefineButton onClick={() => openFilter()}>
                            {t('carFiltersChangeFilter', 'Change filters')}
                        </RefineButton>
                    </NoResults>
                )}
            </CarWrapper>
        </InfiniteScroll>
    )
}

export const CarList = forwardRef(CarListComponent)

const CarWrapper = styled.div`
    padding-bottom: var(--padding);
    @media ${device.laptop} {
        background: none;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 2%;
        row-gap: 20px;
        justify-content: flex-start;
        max-width: ${size.container};
        margin: auto;
        padding-inline: var(--desktopPadding);
    }
`

const CarCardWrapper = styled.div`
    width: 100%;
    @media ${device.laptop} {
        width: 32%;
    }
`

const NoResults = styled.div`
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    @media ${device.laptop} {
        width: 100%;
    }
`

const NoResultsImage = styled.div`
    height: 16rem;
    width: 8rem;
`

const NoResultsTitle = styled.h2`
    margin: 1.5rem auto;
    color: black;
    @media ${device.laptop} {
        color: black;
    }
`

const NoResultsText = styled.div`
    text-align: center;
    max-width: 250px;
    margin: 0 auto 2rem;
    z-index: 10;
    line-height: 1.4rem;
    color: black;
    @media ${device.laptop} {
        color: black;
    }
`

const RefineButton = styled.button`
    background: var(--primaryColor);
    color: var(--white);
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    width: 100%;
    padding: 1rem 0;
    border-radius: 100px;
    border: none;
    z-index: 10;
    @media ${device.laptop} {
        width: auto;
        padding: 1rem 2rem;
        background: var(--primaryColor);
        color: var(--white);
    }
`
export const SpinnerWrapper = styled.div<{ marginTopBottom?: number; direction?: string; align?: string }>`
    display: flex;
    flex-direction: ${({ direction = 'row' }) => direction};
    align-items: ${({ align = 'normal' }) => align};
    justify-content: center;
    background: transparent;
    bottom: 10px;
    z-index: 100;
    width: 100%;
    padding: ${({ marginTopBottom = 10 }) => `${marginTopBottom}px 0`};

    p,
    span {
        max-width: 260px;
        text-align: center;
        color: var(--black);

        @media ${device.laptop} {
            color: var(--black);
        }
    }

    p {
        margin: 10px 0 0 0;
        font-size: 1.2rem;
        font-weight: var(--weight-semiBold);
    }
`
