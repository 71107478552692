import { config, isServer } from '@util/config'

export const pageview = (url: string) => {
    // TEMP FIX
    if (!window || typeof window?.gtag !== 'function' || isServer) return
    window.gtag('config', config.GA_TRACKING_ID, {
        page_path: url,
    })
}

export const trackEvent = ({ action, category, label, value }: gaEvents) => {
    // debugger
    // TEMP FIX
    if (!window || typeof window?.gtag !== 'function' || isServer) return
    // debugger
    window.gtag('event', action, {
        event_category: category,
        event_label: label || null,
        value: value && typeof value === 'object' ? JSON.stringify(value) : value || null,
    })
}

export const sendEcommerceEvent = (eventName, ecommerceData) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
        // Clear the previous ecommerce object
        window.dataLayer.push({ ecommerce: null })

        // Push the new event
        window.dataLayer.push({
            event: eventName,
            ecommerce: ecommerceData,
        })
    } else {
        console.warn('GTM dataLayer is not available.')
    }
}

export const sendGaError = (errorMessage, errorUrl, errorLine) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'javascript_error',
            error_message: errorMessage,
            error_url: errorUrl,
            error_line: errorLine,
        })
    } else {
        console.warn('GTM dataLayer is not available.')
    }
}

export const sendUserId = (userId: string) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'user_data',
            user_id: userId,
        })
    } else {
        console.warn('GTM dataLayer is not available.')
    }
}

export const sendVisitorType = (type: string) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'user_type',
            visitor_type: type,
        })
    } else {
        console.warn('GTM dataLayer is not available.')
    }
}

type gaEvents = {
    action: string
    category: string
    label?: string
    value?: any
}

declare global {
    interface Window {
        gtag: (...args: unknown[]) => void
        dataLayer: Array<Record<string, unknown>>
    }
}
