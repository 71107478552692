import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearch } from '@contexts/search'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device } from '@util/responsive'
import { timeFormat } from '@util/config'
import { useGlobalState } from '@contexts/global'
import { SELF_PICKUP } from '@util/constants'
import { DateTimeSelectorTypes } from '@util/enums'
import { PickerTime } from 'src/types/Time'
import { useTimeSelector } from './useTimeSelector'
import { useBusinessHours } from '@hooks/useBusinessHours'
import { OnFromOrToDateChangeFn } from './DesktopPicker.types'
import { Spinner } from '../Spinner'
import dynamic from 'next/dynamic'

const HomePageDesktopPicker = dynamic(
    () => import('./HomePagePicker').then(({ HomePageDesktopPicker }) => HomePageDesktopPicker),
    {
        ssr: false,
        loading: () => <Spinner />,
    },
)

const DesktopPicker = dynamic(() => import('./DesktopPicker').then(({ DesktopPicker }) => DesktopPicker), {
    ssr: false,
    loading: () => <Spinner />,
})

const MobilePicker = dynamic(() => import('./MobilePicker').then(({ MobilePicker }) => MobilePicker), {
    ssr: false,
    loading: () => <Spinner />,
})

export const TimeSelector: React.FC<{
    specTime: DateTimeSelectorTypes.from | DateTimeSelectorTypes.to
    timeList?: PickerTime[]
    setTimeModal: React.Dispatch<React.SetStateAction<boolean>>
    isModalDateTimePickerDesktop?: boolean | undefined
    onSave?: OnFromOrToDateChangeFn
}> = ({ specTime, setTimeModal: setModal, isModalDateTimePickerDesktop, onSave }) => {
    const [isReady, setIsReady] = useState<boolean | null>(null)
    const { bookingState } = useGlobalState()
    const isDesktop = useMediaQuery(device.laptop)
    const { fromTime, toTime } = useSearch()
    const { businessHoursForD2D, businessHoursForSelfPickup, query: businessHoursQuery } = useBusinessHours({})
    const { timeSlotsQuery, timeSlotList, timeSlotListFiltering } = useTimeSelector(specTime)

    const filteredTimeSlots = useCallback(
        (list: PickerTime[]) => (specTime === DateTimeSelectorTypes.to ? timeSlotListFiltering(list) : list),
        [specTime, timeSlotListFiltering],
    )

    const timeList = useMemo(
        () =>
            bookingState.deliveryType === SELF_PICKUP
                ? filteredTimeSlots(businessHoursForSelfPickup)
                : filteredTimeSlots(timeSlotList),
        [bookingState.deliveryType, businessHoursForSelfPickup, filteredTimeSlots, timeSlotList],
    )

    const defaultSelectedIndex = useMemo(
        () =>
            timeSlotList.findIndex(
                (x) =>
                    format(x.startDate, timeFormat) ==
                    format(specTime === 'from' ? fromTime ?? new Date() : toTime ?? new Date(), timeFormat),
            ),
        [fromTime, specTime, timeSlotList, toTime],
    )

    useEffect(() => {
        setIsReady(true)
    }, [])

    const renderSwitchTimeSelector = useCallback(() => {
        if (isDesktop && !isModalDateTimePickerDesktop) {
            return (
                <HomePageDesktopPicker
                    specTime={specTime}
                    defaultSelectedIndex={defaultSelectedIndex}
                    setTimeModal={setModal}
                    timeList={timeSlotList.length === 0 ? businessHoursForD2D : timeList}
                    onChange={onSave}
                    isLoading={businessHoursQuery.isFetching}
                />
            )
        } else if (isDesktop && isModalDateTimePickerDesktop) {
            return (
                <DesktopPicker
                    specTime={specTime}
                    defaultSelectedIndex={defaultSelectedIndex}
                    setTimeModal={setModal}
                    timeList={timeSlotList.length === 0 ? businessHoursForD2D : timeList}
                    onChange={onSave}
                    isLoading={timeSlotsQuery.isFetching}
                />
            )
        } else {
            return (
                <MobilePicker
                    specTime={specTime}
                    defaultSelectedIndex={defaultSelectedIndex}
                    setTimeModal={setModal}
                    timeList={timeSlotList.length === 0 ? businessHoursForD2D : timeList}
                    onChange={onSave}
                    isLoading={timeSlotsQuery.isFetching}
                />
            )
        }
    }, [
        businessHoursForD2D,
        businessHoursQuery.isFetching,
        defaultSelectedIndex,
        isDesktop,
        isModalDateTimePickerDesktop,
        onSave,
        setModal,
        specTime,
        timeList,
        timeSlotList,
        timeSlotsQuery.isFetching,
    ])

    return isReady ? renderSwitchTimeSelector() : null
}
